// src/pages/Login.jsx
import React, { useEffect } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { loginValidationSchema } from "../utils/validationSchemas";
import { useNavigate } from "react-router-dom";
import "../style/Login.css"; // Import custom CSS for styling

import { loginUser } from "../store/loginslice";
import Loader from "../components/Loader";
import { Alert } from "react-bootstrap";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userLoginLoading, userLoginError, userInfo } = useSelector(
    (state) => state.login
  );
  console.log("check if we found data", userInfo);
  console.log("check login error", userLoginError);
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("Form values:", values);
    localStorage.setItem("email", values.email);
    dispatch(loginUser(values));
    setSubmitting(false);
    resetForm();
  };

  useEffect(() => {
    if (userInfo && userInfo.status === 200) {
      navigate("/verify"); // Navigate to verify page
    }
  }, [userInfo, navigate]);

  if (userLoginLoading) {
    return (
      <div className="text-center mb-3">
        <Loader />
      </div>
    );
  }

  return (
    <Container className="login-container">
      <Card className="login-card">
        <Card.Body>
          <h1 className="text-center">Login</h1>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={loginValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Field
                    type="email"
                    name="email"
                    as={Form.Control}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={isSubmitting}
                >
                  Verify
                </Button>

                {userLoginError && (
                  <Alert variant="danger" className="text-center mt-3">
                    {userLoginError.message || "An unknown error occurred"}
                  </Alert>
                )}

                <div className="login-link text-center mt-3">
                  <span>Don't have an account?</span>
                  <Link to="/signup">Sign Up</Link>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
