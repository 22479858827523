// src/utils/validationSchemas.js
import * as Yup from 'yup';

// Custom regex for email validation (optional)
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Signup validation schema
export const signupValidationSchema = Yup.object({
  name: Yup.string()
    .required('Name is required')
    .min(2, 'Name must be at least 2 characters long'),
  email: Yup.string()
    .required('Email is required')
    .matches(emailRegex, 'Invalid email address'),
  phone: Yup.string()
    .required('phone is required')
    .min(10, 'phone must be at least 10 characters long'),
});

// Login validation schema
export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .required('Email is required')
    .matches(emailRegex, 'Invalid email address'),
});
