import React from "react";
import "../style/Loader.css";

export default function Loader() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100">
      <span className="loader"></span>
    </div>
  );
}
