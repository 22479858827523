// src/pages/Signup.jsx
import React, { useEffect } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, ErrorMessage } from "formik";
import { signupValidationSchema } from "../utils/validationSchemas";
import { useNavigate } from "react-router-dom";
import "../style/Signup.css";
import Loader from "../components/Loader";
import { Alert } from "react-bootstrap";

import { createUser } from "../store/loginslice";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registerCreateLoading, registerCreateError, registerinfo } =
    useSelector((state) => state.login);
  console.log("signup error", registerCreateError);
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("Form values:", values);
    localStorage.setItem("email", values.email);
    dispatch(createUser(values));
    setSubmitting(false);
    resetForm();
  };

  useEffect(() => {
    if (registerinfo && registerinfo.status === 200) {
      navigate("/verify"); // Navigate to verify page
    }
  }, [registerinfo, navigate]);

  if (registerCreateLoading) {
    return (
      <div className="text-center mb-3">
        <Loader />
      </div>
    );
  }

  return (
    <Container className="signup-container">
      <Card className="signup-card">
        <Card.Body>
          <h1 className="text-center">Signup</h1>
          <Formik
            initialValues={{ name: "", email: "", phone: "" }}
            validationSchema={signupValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Field
                    type="text"
                    name="name"
                    as={Form.Control}
                    placeholder="Enter your name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Field
                    type="email"
                    name="email"
                    as={Form.Control}
                    placeholder="Enter your email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group controlId="formPhone">
                  <Form.Label>Phone</Form.Label>
                  <Field
                    type="tel" // Change input type to "tel" for phone numbers
                    name="phone" // Update the name to "phone"
                    as={Form.Control}
                    placeholder="Enter your phone number" // Update placeholder
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  className="w-100"
                  disabled={isSubmitting}
                >
                  Signup
                </Button>
                {registerCreateError && (
                  <Alert variant="danger" className="text-center mt-3">
                    {registerCreateError.message || "An unknown error occurred"}
                  </Alert>
                )}

                <div className="signup-link text-center mt-3">
                  <span>Already have an account? </span>
                  <Link to="/login">Sign In</Link>
                </div>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Signup;
