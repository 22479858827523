import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import "../style/UploadCard.css";
import { uploadFile } from "../store/uploadslice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { Alert } from "react-bootstrap";

const UploadCard = () => {
  const [file, setFile] = useState(null);
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [lengthError, setLengthError] = useState(null);
  const [breadthError, setBreadthError] = useState(null);

  const fileInputRef = useRef(null); // Create a ref for the file input
  const dispatch = useDispatch();
  const { uploadLoading, uploadError } = useSelector((state) => state.upload);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  console.log("check file", file);

  const validateNumberInput = (value, setError) => {
    if (/^\d*\.?\d*$/.test(value)) {
      setError(null); // Valid number
      return value;
    } else {
      setError("Only numeric values are allowed");
      return "";
    }
  };

  const handleLengthChange = (e) => {
    const value = validateNumberInput(e.target.value, setLengthError);
    setLength(value);
  };

  const handleBreadthChange = (e) => {
    const value = validateNumberInput(e.target.value, setBreadthError);
    setBreadth(value);
  };

  const handleUpload = () => {
    if (!length || !breadth) {
      if (!length) setLengthError("Length is required");
      if (!breadth) setBreadthError("Breadth is required");
      return;
    }

    dispatch(
      uploadFile({
        file: file,
        additionalArtifactData: {
          length: length,
          breadth: breadth,
          unit: "ft",
        },
      })
    ).then(() => {
      setFile(null);
      setLength("");
      setBreadth("");
    });
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click event
    }
  };

  if (uploadLoading) {
    return (
      <div className="text-center mb-3">
        <Loader />
      </div>
    );
  }

  return (
    <div className="card upload-card">
      <div className="card-body">
        <h5 className="card-title text-center">Upload 3D Design</h5>
        <div className="text-center mb-3">
          <div className="upload-icon-container" onClick={handleIconClick}>
            <FontAwesomeIcon icon={faCloudArrowUp} size="2x" />
          </div>
          <input
            type="file"
            ref={fileInputRef}
            id="upload-file"
            className="form-control-file d-none"
            onChange={handleFileChange}
          />
          {file && <span>{file.name}</span>}
        </div>
        <div className="form-row mb-3">
          <div className="col">
            <label htmlFor="length">Length</label>
            <div className="input-group mb-3">
              <input
                className={`form-control ${lengthError ? "is-invalid" : ""}`}
                aria-describedby="basic-addon2"
                id="length"
                value={length}
                onChange={handleLengthChange}
                placeholder="Enter length"
              />
              <span className="input-group-text" id="basic-addon2">
                ft
              </span>
              {lengthError && (
                <div className="invalid-feedback">{lengthError}</div>
              )}
            </div>
          </div>
          <div className="col">
            <label htmlFor="breadth">Breadth</label>
            <div className="input-group mb-3">
              <input
                className={`form-control ${breadthError ? "is-invalid" : ""}`}
                aria-describedby="basic-addon2"
                id="breadth"
                value={breadth}
                onChange={handleBreadthChange}
                placeholder="Enter breadth"
              />
              <span className="input-group-text" id="basic-addon2">
                ft
              </span>
              {breadthError && (
                <div className="invalid-feedback">{breadthError}</div>
              )}
            </div>
          </div>
        </div>
        <button className="btn btn-primary mt-3 w-100" onClick={handleUpload}>
          Upload
        </button>
        {uploadError && (
          <Alert variant="danger" className="text-center mt-3">
            {uploadError.message || "An unknown error occurred"}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default UploadCard;
