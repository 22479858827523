import React from "react";
import "../style/Dashboard.css";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
} from "react-bootstrap";

export default function NavbarMenu({ view, setView }) {
  const handleSignOut = () => {
    const persistedStateString = localStorage.getItem("persist:root");
    if (persistedStateString) {
      const persistedState = JSON.parse(persistedStateString);
      if (persistedState.login) {
        delete persistedState.login;
        const updatedPersistedStateString = JSON.stringify(persistedState);
        localStorage.setItem("persist:root", updatedPersistedStateString);
        window.location.href = "/login";
      }
    }
  };

  return (
    <Navbar expand="lg" className="navbar-custom">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src="https://via.placeholder.com/200x300"
            alt="Logo"
            style={{ width: "100px" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <span
              className={`nav-item ${view === "upload" ? "active" : ""}`}
              onClick={() => setView("upload")}
            >
              Upload
            </span>
            <span
              className={`nav-item ${view === "list" ? "active" : ""}`}
              onClick={() => setView("list")}
            >
              List
            </span>
          </Nav>
        </Navbar.Collapse>
        <Nav className="ml-auto">
          <NavDropdown
            id="nav-dropdown-dark-example"
            title="Account"
            menuVariant="dark"
          >
            <NavDropdown.Item onClick={handleSignOut}>Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}
