// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/style/OtpVerification.css */

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.otp-card {
  max-width: 400px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #28282B !important;
  color: white !important;
}

.otp-inputs {
  margin: 20px 0;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.otp-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.otp-input + .otp-input {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/style/OtpVerification.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,wCAAwC;EACxC,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,6CAA6C;AAC/C;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["/* src/style/OtpVerification.css */\n\n.otp-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.otp-card {\n  max-width: 400px;\n  width: 100%;\n  padding: 20px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  background-color: #28282B !important;\n  color: white !important;\n}\n\n.otp-inputs {\n  margin: 20px 0;\n}\n\n.otp-input {\n  width: 40px;\n  height: 40px;\n  text-align: center;\n  font-size: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  outline: none;\n  transition: border-color 0.2s;\n}\n\n.otp-input:focus {\n  border-color: #007bff;\n  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);\n}\n\n.otp-input + .otp-input {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
