import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/** Async thunk to post registration info */
export const createUser = createAsyncThunk(
  "loginManage/postInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://api.vrchitect.rzr.app/api/user/register`,
        payload
      );
      return response.data; // Directly return the response data
    } catch (err) {
      console.error("API Error:", err);
      return rejectWithValue(err.response?.data || "Something went wrong");
    }
  }
);

/** Async thunk to post login info */
export const loginUser = createAsyncThunk(
  "loginManage/loginUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://api.vrchitect.rzr.app/api/user/login`,
        payload
      );
      return response.data; // Directly return the response data
    } catch (err) {
      console.error("API Error:", err);
      return rejectWithValue(err.response?.data || "Something went wrong");
    }
  }
);

/** Async thunk to post login info */
export const verifyUser = createAsyncThunk(
  "loginManage/verifyUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://api.vrchitect.rzr.app/api/user/verify`,
        payload
      );
      return response.data; // Directly return the response data
    } catch (err) {
      console.error("API Error:", err);
      return rejectWithValue(err.response?.data || "Something went wrong");
    }
  }
);

/** Initial state */
const initialState = {
  registerinfo: null,
  registerCreateLoading: false,
  registerCreateError: "",
  userInfo: null, // To hold the logged-in user info
  userLoginLoading: false,
  userLoginError: "",
  verifyInfo: null,
  verifyLoading: false,
  verifyError: "",
};

/** Redux Slice */
const loginSlice = createSlice({
  name: "loginManage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Registration cases
    builder
      .addCase(createUser.pending, (state) => {
        state.registerCreateLoading = true;
        state.registerCreateError = "";
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.registerinfo = action.payload || null;
        state.registerCreateLoading = false;
        state.registerCreateError = "";
      })
      .addCase(createUser.rejected, (state, action) => {
        state.registerCreateLoading = false;
        state.registerCreateError = action.payload || "Failed to register";
      });

    // Login cases
    builder
      .addCase(loginUser.pending, (state) => {
        state.userLoginLoading = true;
        state.userLoginError = "";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userInfo = action.payload || null; // Store the logged-in user data
        state.userLoginLoading = false;
        state.userLoginError = "";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.userLoginLoading = false;
        state.userLoginError = action.payload || "Failed to login";
      });

    // verify cases
    builder
      .addCase(verifyUser.pending, (state) => {
        state.verifyLoading = true;
        state.verifyError = "";
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.verifyInfo = action.payload || null; // Store the logged-in user data
        state.verifyLoading = false;
        state.verifyError = "";
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.verifyLoading = false;
        state.verifyError = action.payload || "Failed to login";
      });
  },
});

/** Export actions and reducer */
export default loginSlice.reducer;
