import React, { useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { useTable, usePagination } from "react-table";
import "../style/listData.css";
import { allFileData } from "../store/uploadslice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

const ListData = () => {
  const dispatch = useDispatch();
  const { allFileLoading, allFileError, allFile } = useSelector(
    (state) => state.upload
  );
  console.log("all list error", allFileError);
  useEffect(() => {
    dispatch(allFileData());
  }, [dispatch]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "slug",
      },
      {
        Header: "View URL",
        accessor: "filename",
        Cell: ({ value }) => (
          <span
            className="icon-view"
            onClick={() => window.open(`https://vrchitect.rzr.app/experience?name=${value.split('.')[0]}`, "_blank")}
          >
            <FontAwesomeIcon icon={faEye} />
          </span>
        ),
      },
      {
        Header: "Length",
        accessor: "length",
      },
      {
        Header: "Breadth",
        accessor: "breadth",
      },
      {
        Header: "Actions",
        Cell: ({ row: { original } }) => (
          <span className="icon-delete" onClick={() => handleDelete(id)}>
            <FontAwesomeIcon icon={faTrash} />
          </span>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    return (
      allFile?.data?.map((item) => ({
        _id: item._id, // Ensure each item has a unique `_id` field
        slug: item.slug,
        filename: item.filename,
        length: item.additionalArtifactData?.length || "-",
        breadth: item.additionalArtifactData?.breadth || "-",
      })) || []
    );
  }, [allFile]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  const handleDelete = (id) => {
    // Handle delete logic with the unique id
  };

  if (allFileLoading) {
    return (
      <div className="text-center mb-3">
        <Loader />
      </div>
    );
  }

  return (
    <div className="table-container">
      {allFileError && (
        <Alert variant="info" className="text-center mb-3">
          No data available
        </Alert>
      )}
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </Button>
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>
        <Button
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>
        <span>
          Page {pageIndex + 1} of {pageOptions.length}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ListData;
