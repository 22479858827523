import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import UploadCard from "../components/UploadCard";
import ListData from "../components/ListData";
import NavbarMenu from "../components/NavbarMenu";

const Dashboard = () => {
  const [view, setView] = useState("upload");
  return (
    <div>
      <NavbarMenu view={view} setView={setView} />
      <div className="container mt-4">
        {view === "upload" && <UploadCard />}
        {view === "list" && <ListData />}
      </div>
    </div>
  );
};

export default Dashboard;
