// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.login-card {
  width: 100%;
  max-width: 400px; /* Adjust the card width as needed */
  border: 1px solid #ddd; /* Card border color */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  background-color: #28282B !important;
  color: white !important;

}

.login-card .card-body {
  padding: 20px;
}

.login-card h1 {
  margin-bottom: 20px; /* Margin below the title */
}

.login-card .form-group {
  margin-bottom: 15px; /* Space between form groups */
}

.login-card .btn {
  margin-top: 10px; /* Space above the button */
}

.signup-link {
  margin-top: 20px; /* Space above the signup link section */
}

.signup-link span {
  margin-right: 5px; /* Space between text and link */
}

.signup-link a {
  color: #007bff; /* Link color */
  text-decoration: none; /* Remove underline */
}

.signup-link a:hover {
  text-decoration: underline; /* Underline on hover */
}
`, "",{"version":3,"sources":["webpack://./src/style/Login.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,yBAAyB;AAC1C;;AAEA;EACE,WAAW;EACX,gBAAgB,EAAE,oCAAoC;EACtD,sBAAsB,EAAE,sBAAsB;EAC9C,kBAAkB,EAAE,oBAAoB;EACxC,aAAa,EAAE,4BAA4B;EAC3C,oCAAoC;EACpC,uBAAuB;;AAEzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB,EAAE,2BAA2B;AAClD;;AAEA;EACE,mBAAmB,EAAE,8BAA8B;AACrD;;AAEA;EACE,gBAAgB,EAAE,2BAA2B;AAC/C;;AAEA;EACE,gBAAgB,EAAE,wCAAwC;AAC5D;;AAEA;EACE,iBAAiB,EAAE,gCAAgC;AACrD;;AAEA;EACE,cAAc,EAAE,eAAe;EAC/B,qBAAqB,EAAE,qBAAqB;AAC9C;;AAEA;EACE,0BAA0B,EAAE,uBAAuB;AACrD","sourcesContent":["/* src/styles/Login.css */\n.login-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full viewport height */\n}\n\n.login-card {\n  width: 100%;\n  max-width: 400px; /* Adjust the card width as needed */\n  border: 1px solid #ddd; /* Card border color */\n  border-radius: 8px; /* Rounded corners */\n  padding: 20px; /* Padding inside the card */\n  background-color: #28282B !important;\n  color: white !important;\n\n}\n\n.login-card .card-body {\n  padding: 20px;\n}\n\n.login-card h1 {\n  margin-bottom: 20px; /* Margin below the title */\n}\n\n.login-card .form-group {\n  margin-bottom: 15px; /* Space between form groups */\n}\n\n.login-card .btn {\n  margin-top: 10px; /* Space above the button */\n}\n\n.signup-link {\n  margin-top: 20px; /* Space above the signup link section */\n}\n\n.signup-link span {\n  margin-right: 5px; /* Space between text and link */\n}\n\n.signup-link a {\n  color: #007bff; /* Link color */\n  text-decoration: none; /* Remove underline */\n}\n\n.signup-link a:hover {\n  text-decoration: underline; /* Underline on hover */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
