// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  --loader-size: 100px;
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #c4abe3 #c4abe3 transparent #c4abe3;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #773dbe #773dbe;
  width: calc(var(--loader-size) - 15px);
  height: calc(var(--loader-size) - 15px);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.7s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: calc(var(--loader-size) - 30px);
  height: calc(var(--loader-size) - 30px);
  border-color: #fff #fff transparent transparent;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/Loader.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,yBAAyB;EACzB,0BAA0B;EAC1B,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,iDAAiD;EACjD,sBAAsB;EACtB,wCAAwC;AAC1C;AACA;;EAEE,WAAW;EACX,sBAAsB;EACtB,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,MAAM;EACN,SAAS;EACT,YAAY;EACZ,iBAAiB;EACjB,qDAAqD;EACrD,sCAAsC;EACtC,uCAAuC;EACvC,kBAAkB;EAClB,sBAAsB;EACtB,4CAA4C;EAC5C,+BAA+B;AACjC;AACA;EACE,sCAAsC;EACtC,uCAAuC;EACvC,+CAA+C;EAC/C,sCAAsC;AACxC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".loader {\n  --loader-size: 100px;\n  width: var(--loader-size);\n  height: var(--loader-size);\n  border-radius: 50%;\n  display: inline-block;\n  position: relative;\n  border: 3px solid;\n  border-color: #c4abe3 #c4abe3 transparent #c4abe3;\n  box-sizing: border-box;\n  animation: rotation 1.5s linear infinite;\n}\n.loader::after,\n.loader::before {\n  content: \"\";\n  box-sizing: border-box;\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  margin: auto;\n  border: 3px solid;\n  border-color: transparent transparent #773dbe #773dbe;\n  width: calc(var(--loader-size) - 15px);\n  height: calc(var(--loader-size) - 15px);\n  border-radius: 50%;\n  box-sizing: border-box;\n  animation: rotationBack 0.7s linear infinite;\n  transform-origin: center center;\n}\n.loader::before {\n  width: calc(var(--loader-size) - 30px);\n  height: calc(var(--loader-size) - 30px);\n  border-color: #fff #fff transparent transparent;\n  animation: rotation 2s linear infinite;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n@keyframes rotationBack {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(-360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
