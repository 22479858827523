import React, { useRef, useState, useEffect } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../style/OtpVerification.css"; // Import custom CSS for styling
import { verifyUser } from "../store/loginslice";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { Alert } from "react-bootstrap";

const OtpVerification = () => {
  const numberOfDigits = 6;
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const otpBoxReference = useRef([]);
  const [email, setEmail] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verifyLoading, verifyError, verifyInfo } = useSelector(
    (state) => state.login
  );

  console.log("verify error", verifyError);
  useEffect(() => {
    // Retrieve the email from local storage when the component mounts
    const email = localStorage.getItem("email");
    if (email) {
      setEmail(email);
    }
  }, []);

  function handleChange(value, index) {
    if (/^\d?$/.test(value)) {
      // Ensure only numeric input
      let newArr = [...otp];
      newArr[index] = value;
      setOtp(newArr);

      // Move to next input after a slight delay to ensure the state is updated first
      if (value && index < numberOfDigits - 1) {
        setTimeout(() => {
          otpBoxReference.current[index + 1].focus();
        }, 100);
      }
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
  }

  function handleOtp(e) {
    e.preventDefault();
    dispatch(verifyUser({ email, pin: otp.join("") }));
  }

  // Effect that listens for changes in verifyInfo and navigates when status is 200
  useEffect(() => {
    if (verifyInfo && verifyInfo.status === 200) {
      setOtp(new Array(numberOfDigits).fill("")); // Clear the OTP input
      navigate("/dashboard"); // Navigate to the dashboard
    }
  }, [verifyInfo, navigate, numberOfDigits]);

  if (verifyLoading) {
    return (
      <div className="text-center mb-3">
        <Loader />
      </div>
    );
  }

  return (
    <Container className="otp-container">
      <Card className="otp-card">
        <Card.Body>
          <h1 className="text-center">Verify Your Email</h1>
          <p className="text-center">
            We have sent a verification code to <strong>{email}</strong>
          </p>
          <Form className="d-flex gap-3 justify-content-center">
            {otp.map((digit, index) => (
              <Form.Group key={index} className="mb-3">
                <Form.Control
                  type="password"
                  value={digit}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                  className="text-center"
                  maxLength="1"
                />
              </Form.Group>
            ))}
          </Form>

          <Button variant="primary" onClick={handleOtp} className="mt-3 w-100">
            Verify OTP
          </Button>

          {verifyError && (
            <Alert variant="danger" className="text-center mt-3">
              {verifyError.message || "An unknown error occurred"}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default OtpVerification;
