// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Signup.css */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.signup-card {
  width: 100%;
  max-width: 400px; /* Adjust the card width as needed */
  border: 1px solid #ddd; /* Card border color */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding inside the card */
  background-color: #28282B !important;
  color: white !important;
}

.signup-card .card-body {
  padding: 20px;
}

.signup-card h1 {
  margin-bottom: 20px; /* Margin below the title */
}

.signup-card .form-group {
  margin-bottom: 15px; /* Space between form groups */
}

.signup-card .btn {
  margin-top: 10px; /* Space above the button */
}
`, "",{"version":3,"sources":["webpack://./src/style/Signup.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB,EAAE,oCAAoC;EACtD,sBAAsB,EAAE,sBAAsB;EAC9C,kBAAkB,EAAE,oBAAoB;EACxC,aAAa,EAAE,4BAA4B;EAC3C,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB,EAAE,2BAA2B;AAClD;;AAEA;EACE,mBAAmB,EAAE,8BAA8B;AACrD;;AAEA;EACE,gBAAgB,EAAE,2BAA2B;AAC/C","sourcesContent":["/* src/styles/Signup.css */\n.signup-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.signup-card {\n  width: 100%;\n  max-width: 400px; /* Adjust the card width as needed */\n  border: 1px solid #ddd; /* Card border color */\n  border-radius: 8px; /* Rounded corners */\n  padding: 20px; /* Padding inside the card */\n  background-color: #28282B !important;\n  color: white !important;\n}\n\n.signup-card .card-body {\n  padding: 20px;\n}\n\n.signup-card h1 {\n  margin-bottom: 20px; /* Margin below the title */\n}\n\n.signup-card .form-group {\n  margin-bottom: 15px; /* Space between form groups */\n}\n\n.signup-card .btn {\n  margin-top: 10px; /* Space above the button */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
