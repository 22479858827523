import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes = ({ children }) => {
  const { userInfo } = useSelector((state) => state.login); // Replace with your actual login state

  // If not logged in, redirect to the login page
  if (!userInfo) {
    return <Navigate to="/login" replace />;
  }

  // If logged in, render the protected page
  return children;
};

export default ProtectedRoutes;
