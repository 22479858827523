import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Index = () => {
  const navigate = useNavigate();



  const handleRedirectToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    handleRedirectToLogin();
  }, []);
  return (
    <div>

    </div>
  );
};

export default Index;
